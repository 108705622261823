import React, { Component } from "react";
import { Route, Switch, Link } from "react-router-dom";
import namelogo from "./images/SP-02.png";
import html5 from "./images/html5.svg";
import css from "./images/css-3.svg";
import js from "./images/javascript.svg";
import node from "./images/nodejs-icon.svg";
import nginx from "./images/nginx.svg";
import knock from "./images/knockout.svg";
import flask from "./images/flask.svg";
import npm from "./images/npm.svg";
import logo from "./images/SP-01.png";
import bw from "./images/BW02.jpg";
import git from "./images/git-icon.svg";
import github from "./images/github-icon.svg";
import aws from "./images/aws.svg";
import mysql from "./images/mysql-6.svg";
import pgre from "./images/postgresql.svg";
import python from "./images/python-5.svg";
import react from "./images/react.svg";

import "./App.css";

class MyApp extends Component {
  render() {
    return (
      <div className="App">
        <Switch>
          <Route
            exact
            path="/"
            render={() => (
              <div className="IndexBlock">
                <div className="navbar">
                  <Link to="/">
                    <img className="logo-image" src={logo} />
                  </Link>
                  <ul className="Nav-List">
                    <li><span></span><Link to="/about" className="Menu-Text">About</Link></li>
                    <li><span></span><Link to="/contact" className="Menu-Text">Contact</Link></li>
                  </ul>
                </div>
                <img className="vertical-text" src={namelogo} />
                <div className="main-index">
                  <h1>I create_</h1>
                  <p> Beautiful Experiences</p>
                </div>
              </div>
            )}
          />

          <Route
            path="/about"
            render={() => (
              <div className="IndexBlock">
                <div className="navbar">
                  <Link to="/">
                    <img className="logo-image" src={logo} />
                  </Link>
                  <img className="vertical-text" src={namelogo} />
                </div>

                <div className="one-line-intro">
                  <h1>ABOUT</h1>
                  <p>
                    Hi, I'm Siddharth Pandey, A freelance FullStack Developer
                    with a penchant for designing beautiful ideas and giving
                    them life.
                  </p>
                  <ul className="Skills">
                    <li>
                      <img src={html5} />
                    </li>
                    <li>
                      <img src={css} />
                    </li>
                    <li>
                      <img src={js} />
                    </li>
                    <li>
                      <img src={node} />
                    </li>
                    <li>
                      <img src={npm} />
                    </li>
                    <li>
                      <img src={nginx} />
                    </li>
                    <li>
                      <img src={knock} />
                    </li>
                    <li>
                      <img src={flask} />
                    </li>
                    <li>
                      <img src={git} />
                    </li>
                    <li>
                      <img src={github} />
                    </li>
                    <li>
                      <img src={aws} />
                    </li>
                    <li>
                      <img src={pgre} />
                    </li>
                    <li>
                      <img src={mysql} />
                    </li>
                    <li>
                      <img src={python} />
                    </li>
                    <li>
                      <img src={react} />
                    </li>
                  </ul>
                  <div className="ppfoto-div">
                    <img className="ppfoto" src={bw} />
                  </div>
                </div>
              </div>
            )}
          />

          <Route
            path="/contact"
            render={() => (
              <div className="IndexBlock">
                <div className="navbar">
                  <Link to="/">
                    <img className="logo-image" src={logo} />
                  </Link>
                </div>
                <img className="vertical-text" src={namelogo} />
                <div className="Contact-div">
                  <div className="Social-Links">
                    <ul>
                      <li>
                        <a href="https://twitter.com/siddharthpand8y">
                          TWITTER
                        </a>
                      </li>
                      <li>
                        <a href="https://github.com/siddharth-pand8y">GITHUB</a>
                      </li>
                      <li>
                        <a href="https://www.linkedin.com/in/siddharth-pandey-/">
                          LINKED-IN
                        </a>
                      </li>
                    </ul>
                  </div>
                  <h className="Contact-Me-Heading">Hello, </h>
                  <p className="Contact-Me-Text">
                    Want to make something great? Let me help you.
                    <br />
                    Make a plan. Make a difference. Make it big.
                    <br />
                    <br />
                    For freelance work, I am available from
                    <br /> <span className="date-high">Febraury' 19</span>
                  </p>
                  <a
                    href="mailto:hello@siddharth-pandey.com"
                    className="Contact-Me"
                  >
                    Contact Me
                  </a>

                  <p className="Copyright-Text">
                    &copy; 2018 All Rights Reserved
                  </p>
                </div>
              </div>
            )}
          />
        </Switch>
      </div>
    );
  }
}



export default MyApp;
